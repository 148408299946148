import React from 'react'
import Layout from '../components/Layout'
export default function NotFound() {
    return (
        <Layout>
            <h1>Page Not Found</h1>
            <p>Oops, we couldn't find this page!</p>
        </Layout>
    )
}
